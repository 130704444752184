import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";

//^ 2FA http request =================================================================

export async function postSet2FAProfileHandler({ twoFactAuth, twoFactEmail, twoFactPhone, phoneCode }) {
  const url = `api/v1/account/set2FAProfile`;

  let data = {};

  if (twoFactAuth === "1") {
    data = {
      two_fact_auth: twoFactAuth,
      two_fact_email: twoFactEmail,
    };
  } else if (twoFactAuth === "2") {
    data = {
      two_fact_auth: twoFactAuth,
      two_fact_phone: twoFactPhone,
      phonecode: phoneCode,
    };
  } else if (twoFactAuth === "3") {
    data = {
      two_fact_auth: twoFactAuth,
      two_fact_email: twoFactEmail,
      two_fact_phone: twoFactPhone,
      phonecode: phoneCode,
    };
  } else {
    data = {
      two_fact_auth: twoFactAuth,
    };
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken", null),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while setting two-factor authentication.");

      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function postVerifyOTP({ otp, twoFactEmail, twoFactPhone, phoneCode, factType }) {
  const initialValue = { otp, two_fact_email: twoFactEmail, two_fact_phone: twoFactPhone, phonecode: phoneCode };
  let data = initialValue;

  if (factType === "email") {
    data = { otp, two_fact_email: twoFactEmail };
  } else if (factType === "mobile") {
    data = { otp, two_fact_phone: twoFactPhone, phonecode: phoneCode };
  } else if (factType === "both") {
    data = initialValue;
  }

  const url = "api/v1/account/verify2FAOTP";

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", null),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while verifying a OTP");

      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

//^ 2FA http request =================================================================

//^ login http request =================================================================

export async function postLogin({ username, password }) {
  let url = "api/v1/auth/login";

  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: "POST",
      body: formData,
      cache: "no-store",
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while logging in.");
      
      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function postVerify2faOtp({ username, otp }) {
  let url = "api/v1/auth/verify-2fa-otp";

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, otp }),
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while verifying 2fa OTP.");

      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function postResendVerify2faOtp({ username }) {
  let url = "api/v1/auth/resend-2fa-otp";

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username }),
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while Resending 2FA OTP.");

      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

//^ login http request =================================================================

//^ Subscription Packages http request =================================================================

export async function getSubscriptionPlansHandler(formData) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-package`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", null),
      },
      body: formData,
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while getting subscription plans.");

      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getSubscriptedPackageHandler() {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-package`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", null),
      },
    });

    if (!response.ok) {
      const errorCode = await response.json();

      const error = new Error("An Error has occurred while getting subscribed plan.");

      error.code = response.status;
      error.info = errorCode;

      throw error;
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getInvoiceByIdHandler(invoiceId) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-invoice`, {
    method: "POST",
    headers: { authToken: getCookie("authToken", null), "Content-Type": "application/json" },
    body: JSON.stringify({ invoice_id: invoiceId }),
  });

  if (!response.ok) {
    const errorCode = await response.json();
    const error = new Error("An Error has occurred while getting invoice.");
    error.code = response.status;
    error.info = errorCode;
    throw error;
  }

  return await response.json();
}

export async function subscribePackageHandler(formData) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-package`, {
    method: "POST",
    body: formData,
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    const errorCode = await response.json();
    const error = new Error("An Error has occurred while subscribing package.");
    error.code = response.status;
    error.info = errorCode;
    throw error;
  }

  return await response.json();
}

export async function downgradeServiceHandler({ planId: plan_id, validity, serviceId: service_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/downgrade-service`, {
    method: "POST",
    headers: { "Content-Type": "application/json", authToken: getCookie("authToken", undefined) },
    body: JSON.stringify({ plan_id, validity, service_id }),
  });

  if (!response.ok) {
    const errorCode = await response.json();
    const error = new Error("An Error has occurred while downgrading service plan.");
    error.code = response.status;
    error.info = errorCode;
    throw error;
  }

  return await response.json();
}
export async function downgradePackageHandler({ id, validity }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/downgrade-package`, {
    method: "POST",
    headers: { "Content-Type": "application/json", authToken: getCookie("authToken", undefined) },
    body: JSON.stringify({ package_id: id, validity }),
  });

  if (!response.ok) {
    const errorCode = await response.json();
    const error = new Error("An Error has occurred while downgrading package.");
    error.code = response.status;
    error.info = errorCode;
    throw error;
  }

  return await response.json();
}

//^ Subscription Packages http request =================================================================

//^ Notification http request =================================================================

export async function getAllNotificationHandler({ limit = 10, offset = 0, module = 1, recent = 10 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-notifications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify({ limit, offset, module, recent }),
  });

  if (!response.ok) {
    const errorData = await response.json();

    const error = new Error("An Error occurred while getting all notifications.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function setNotificationCountHandler({ status = 0, notification_id: notifcation_id = 0 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/set-notification-status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", undefined),
    },
    body: JSON.stringify({ status, notifcation_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while setting notification count.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Notification http request =================================================================

export async function changePasswordHandler(formData) {
  let response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/change-password`, {
    method: "POST",
    body: formData,
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while changing password.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getProfileImageHandler({ type = "crop" }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/get-profile-img`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ type }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting profile image.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  if (response.status === 200) {
    return await response.blob();
  } else if (response.status === 204) {
    return await response.json();
  }
}

export async function resendLinkHandler(email) {
  const formData = new FormData();
  formData.append("username", email);

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/resend-link`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while resending link.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Service http request =============================================================================

export async function getServiceDetails({ serviceId: service_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-service-detail`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ service_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting service details.");
    error.info = errorData;
    error.code = 500;
    throw error;
  }

  return await response.json();
}

export async function getServicePlan({ planId: plan_id, serviceId: service_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-plan`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ plan_id, service_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting service plan.");
    error.info = errorData;
    error.code = 500;
    throw error;
  }

  return await response.json();
}

export async function addPaymentHandler(formData) {
  let response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/payment/add`, {
    method: "POST",
    body: formData,
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while adding payment.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function buyWalletHandler(formData) {
  let response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/wallet/buy`, {
    method: "POST",
    body: formData,
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while buying wallet.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function subscribeServiceHandler({ validity, plan_id, service_id, service_start_date }) {
  const data = {
    validity,
    plan_id,
    service_id,
    service_start_date,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/subscribe-service`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while subscribing service.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getServiceInvoiceHandler({ invoiceId: invoice_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-service-invoice`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ invoice_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting service invoice.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Service http request =============================================================================

//^ SITE Settings http request =============================================================================

export async function getSiteSettingsHandler({ moduleId: module_id = 1 }) {
  const formData = new FormData();
  formData.append("module_id", module_id);

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting site settings.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ SITE Settings http request =============================================================================

//^ Profile http request =============================================================================

export async function setProfileImgHandler({ cropFile: crop_file, originalFile: original_file }) {
  const formData = new FormData();
  formData.append("crop_file", crop_file);
  formData.append("original_file", original_file);

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/set-profile`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while setting profile.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function setProfileInfoHandler({ formData }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/set-profile`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while setting profile.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function resetProfileImgHandler({ resetImg: reset_img = 1 }) {
  const formData = new FormData();
  formData.append("reset_img", reset_img);

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/set-profile`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while resetting profile.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Profile http request =============================================================================

//^ Profile http request =============================================================================

export async function getInvoiceListHandler() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-invoice-list`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting invoice list.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Profile http request =============================================================================

//^ Promo code http request =============================================================================

export async function validatePromoCodeHandler({ promoCode: promocode }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/validate-promocode`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ promocode }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while validating promo code.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Promo code http request =============================================================================

//^ Connection http request =============================================================================

export async function getUserListsHandler({ user_id, page, limit, search, type }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/users`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id, page, limit, search, type }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting user list.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getUserListsSummaryHandler() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/summary`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting user list summary.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getUserInvitationHandler({ page = 1, limit = 4 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/invitation`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ page, limit }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting user invitation.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getFollowersListHandler(limit = 100, page = 1, type = "followed") {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/follow/list`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ limit, page, type }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting followers list.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function getUserListHandler({ type, limit = 8 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/users`, {
    method: "POST",
    headers: { authToken: getCookie("authToken", null), "Content-Type": "application/json" },
    body: JSON.stringify({ type, limit }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while getting user list.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function rejectConnectionHandler({ connection_id, type = "reject" }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/reject`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ connection_id, type }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while rejecting connection.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }
  return await response.json();
}

export async function deleteConnectionHandler({ connection_id, type = "single" }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/delete`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ connection_id, type }),
  });

  if (!response.status) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while deleting connection.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function acceptConnectionHandler({ connection_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/accept`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ connection_id }),
  });

  if (!response.status) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while accepting connection.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function requestConnectionHandler({ user_id }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/connection/request`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id }),
  });

  if (!response.status) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while requesting connection.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

//^ Connection http request =============================================================================
