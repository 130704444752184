import React, { useEffect, useState } from "react";
import AuthFooter from "./AuthFooter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";

//^ layouts
import Head from "../../layout/head/Head";

//^ controller
import { resendLinkRequest, resetLinkRequest } from "../../controller/auth_controller";

//^ post http request
import { postLogin } from "../../http/post-api";

//^ component
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";

//^ mui
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import LoginTwoFactOtpVerification from "./login-two-fact-otp-verification/LoginTwoFactOtpVerification";
import LogoFetch from "./LogoFetch";
import { CustomButton } from "../../themes/CustomComponent";
import { color } from "framer-motion";
// import ReactFacebookLogin from "react-facebook-login";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2";

const Login = () => {
  //^ loading states
  const [loading, setLoading] = useState(false);
  const [loadingElement, setLoadingElement] = useState(false);

  const theme = useTheme();
  //^ success states
  const [passState, setPassState] = useState(false);
  const [successVal, setSuccessVal] = useState("");

  //^ error states
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorVal, setError] = useState("");
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);

  //^ twoFact states
  const [twoFact, setTwoFact] = useState(false);
  const [twoFactLoginRes, SetTwoFactLoginRes] = useState(undefined);

  //^ other states
  const [username, setUsername] = useState("");
  const [loginWith, setLoginWith] = useState("");
  const navigate = useNavigate();

  const roleId = Cookies.get("roleId");

  useEffect(() => {
    const cookieValue = Cookies.get("authToken");
    const role = Cookies.get("role");
    const cookieVal = Cookies.get("cookieVal");
    const roleId = Cookies.get("roleId");

    if (cookieValue && role === 1) {
      window.location.href = process.env.REACT_APP_ADMIN_PAGE_URL;
      Cookies.remove("dashboard", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }

    if (cookieValue && role === 3) {
      if (cookieVal === "clientRole3") {
        if (roleId) {
          window.location.href = roleId;
        } else {
          window.location.href = process.env.REACT_APP_LANDINGPAGE_URL;
        }
        Cookies.remove("cookieVal", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      } else {
        window.location.href = process.env.REACT_APP_LANDINGPAGE_URL;
      }
      Cookies.remove("dashboard", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }

    if (cookieValue && role === 2) {
      window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
      Cookies.remove("dashboard", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const cookieVal = Cookies.get("cookieVal");

  const forgotClick = async () => {
    let username = document.getElementById("username").value;
    if (username.length > 0) {
      setLoadingElement("forgot");
      setLoading(true);
      let resendLinkRes = await resetLinkRequest(username);
      if (resendLinkRes.status) {
        setSuccessVal(resendLinkRes.message);
        setError(false);
      } else {
        setSuccessVal("");
        setError(resendLinkRes.message);
      }
    } else {
      setError("Enter username to send reset password link");
      setSuccessVal("");
    }
    setLoadingElement("");
    setLoading(false);
  };

  //^ post login mutation query
  const {
    isPending: postLoginIsPending,
    mutate: postLoginMutate,
    reset: postLoginReset,
  } = useMutation({
    mutationKey: ["post-login"],
    mutationFn: postLogin,
    onSuccess: (data) => {
      setLoadingElement("");
      setLoading(false);

      if (data.status === true) {
        setSuccessVal(data.message);
        setError(false);

        if (!data.data.two_fa) {
          setTwoFact(data.data.two_fa);
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

          Cookies.set("authToken", data.data.authToken, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("username", data.data.username, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("userId", data.data.user_id, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("phone", data.data.phone, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("email", data.data.email, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("role", data.data.role?.id, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          const role = data.data.role?.id;
          const currentURL = Cookies.get("currentURL");
          if (currentURL) {
            window.location.href = currentURL;
          } else {
            window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
          }

          if (role === 1) {
            window.location.href = process.env.REACT_APP_ADMIN_PAGE_URL; // Redirect to client URL
            Cookies.remove("dashboard", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
          }
          if (role === 2) {
            if (cookieVal === "clientRole3") {
              window.location.href = process.env.REACT_APP_LANDINGPAGE_URL;
              Cookies.remove("cookieVal", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
              Cookies.remove("roleId", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            } else {
              window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
              Cookies.remove("cookieVal", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
              Cookies.remove("roleId", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            }
          }
          if (role === 3) {
            if (cookieVal === "clientRole3") {
              // console.log(typeof cookieVal + "cookieVal :" + cookieVal);
              // console.log("roleId :" + roleId);
              if (roleId) {
                window.location.href = roleId;
                Cookies.remove("cookieVal", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
                Cookies.remove("roleId", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
              } else {
                window.location.href = process.env.REACT_APP_LANDINGPAGE_URL;
                Cookies.remove("cookieVal", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
                Cookies.remove("roleId", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
              }
            } else {
              window.location.href = process.env.REACT_APP_CLIENT_URL; // Redirect to client URL
            }
          }
          Cookies.remove("dashboard", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        } else {
          SetTwoFactLoginRes(data);
          setTwoFact(data.data.two_fa);
        }
      } else {
        setError(data.message);
        setSuccessVal("");

        if (data.errors) setValidationErrors(data.errors);
        else setValidationErrors([]);

        if (data?.data?.verifyRequired && data?.data?.verifyRequired === "1") {
          setIsVerificationRequired(true);
        } else {
          setIsVerificationRequired(false);
        }
      }

      postLoginReset();
    },
  });

  const onFormSubmit = async (formData) => {
    const trimmedNameValue = formData.name.trim();
    const trimmedPasscodeValue = formData.passcode.trim();

    setError("");

    if (!trimmedNameValue.length > 0 || !trimmedPasscodeValue.length > 0) {
      setError("Invalid credentials");
      setSuccessVal("");
      return false;
    }

    setLoading(true);
    setLoadingElement("login");
    setUsername(formData.name);

    postLoginMutate({ password: formData.passcode, username: formData.name });
  };

  const resendLink = async () => {
    let username = document.getElementById("username").value;
    if (username.length > 0) {
      setLoadingElement("resend");
      setLoading(true);
      let resendLinkRes = await resendLinkRequest(username);
      if (resendLinkRes.status) {
        setSuccessVal(resendLinkRes.message);
        setError(false);
      } else {
        setSuccessVal("");
        setError(resendLinkRes.message);
      }
    } else {
      setError("Enter username to resend activation link");
      setSuccessVal("");
    }
    setLoadingElement("");
    setLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const [facebookData, setFacebookData] = useState(null); // State to store Facebook user data

  // const responseFacebook = (response) => {
  //   if (response.accessToken) {
  //     setFacebookData(response); // Store Facebook user data
  //     if (response.email) {
  //       setLoginWith("facebook");
  //       checkUserExist(response.email);
  //     }
  //   }
  // };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     try {
  //       const response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
  //         headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.status} ${response.statusText}`);
  //       }

  //       const profile = await response.json();
  //       const email = profile?.email;
  //       if (profile?.email) {
  //         setLoginWith("google");
  //         checkUserExist(email);
  //       }
  //     } catch (error) {
  //       alert("Google login failed: " + (error.message || "An error occurred"));
  //       console.error("Google login failed", error);
  //     }
  //   },
  //   onError: () => {
  //     alert("Google login failed");
  //     console.error("Google login failed");
  //   },
  // });

  // const checkUserExist = async (email) => {
  //   const dataToSend = { email };
  //   try {
  //     const formdata = new FormData();
  //     formdata.append("email", dataToSend?.email);

  //     let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/verify-status`, {
  //       method: "POST",
  //       body: formdata,
  //     });
  //     const exisitUser = await res.json();

  //     if (exisitUser?.data?.verify_email === "1") {
  //       console.log(exisitUser);
  //       Swal.fire({
  //         icon: "success",
  //         title: "User Verified",
  //         text: "You can access our platform",
  //         focusConfirm: false,
  //       });
  //     } else if (exisitUser?.data?.verify_email === "0") {
  //       if (exisitUser?.message === "Please verify your email address") {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Unable to login!",
  //           text: exisitUser?.message,
  //           focusConfirm: false,
  //         });
  //       } else if (exisitUser?.message === "User not found") {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Unable to login!",
  //           text: exisitUser?.message,
  //           focusConfirm: false,
  //         });
  //         console.log("login :" + loginWith);
  //         const now = new Date();
  //         const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //         Cookies.set("verify", loginWith, {
  //           domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //           expires: expires,
  //         });
  //         navigate(`/verify`);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("error:", error);
  //   }
  // };

  return (
    <>
      {twoFact ? (
        <LoginTwoFactOtpVerification
          mobile={twoFactLoginRes?.data?.phone}
          email={twoFactLoginRes?.data?.email}
          type={twoFactLoginRes?.data?.two_fact_auth}
          username={username}
        />
      ) : (
        <>
          <Head title="Login" />
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-2 text-center">
              <LogoFetch />
            </div>

            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Login</BlockTitle>
                  <BlockDes className="d-none">
                    <p>Access {process.env.REACT_APP_PROJECT_NAME} using your email or username and password.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {successVal && (
                <div className="mb-3">
                  <Alert color="success" className="alert-icon">
                    <Icon name="check" />
                    {successVal}
                  </Alert>
                </div>
              )}
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {errorVal}
                    {validationErrors.length > 0 && (
                      <ul style={{ listStyle: "circle" }}>
                        {validationErrors.map((error, key) => (
                          <li key={key}>{error}</li>
                        ))}
                      </ul>
                    )}
                  </Alert>
                </div>
              )}
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email or Username
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="username"
                      tabIndex={1}
                      {...register("name", { required: "This field is required" })}
                      placeholder="Enter your email address or username"
                      className="form-control-lg bg-transparent border form-control"
                    />
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                      }}
                      className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                    >
                      <Icon name="eye" className="passcode-icon icon-show"></Icon>

                      <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                    </a>
                    <input
                      type={passState ? "text" : "password"}
                      id="password"
                      tabIndex={2}
                      {...register("passcode", { required: "This field is required" })}
                      placeholder="Enter your password"
                      className={`form-control-lg bg-transparent border form-control ${
                        passState ? "is-hidden" : "is-shown"
                      }`}
                    />
                    {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <button
                      type="button"
                      className="link link-primary link-sm"
                      onClick={forgotClick}
                      disabled={loading && loadingElement === "forgot"}
                    >
                      {loading && loadingElement === "forgot" ? "Sending Reset Password Link" : "Forgot Password?"}
                    </button>
                    {isVerificationRequired && (
                      <CustomButton
                        type="button"
                        className="link link-primary link-sm text-white"
                        style={{ cursor: `${loading && loadingElement === "resend" ? "wait" : "pointer"}` }}
                        onClick={resendLink}
                        disabled={loading && loadingElement === "resend"}
                      >
                        {loading && loadingElement === "resend"
                          ? "Resending Activation Link"
                          : "Resend Activation Link"}
                      </CustomButton>
                    )}
                  </div>
                  <Stack>
                    <CustomButton
                      variant={"contained"}
                      size="large"
                      type="submit"
                      color="primary"
                      disabled={postLoginIsPending}
                      startIcon={
                        (loading && loadingElement === "Login") || postLoginIsPending ? (
                          <Stack alignItems={"center"} justifyContent={"center"}>
                            <Spinner
                              style={{ borderWidth: "2px" }}
                              size="sm"
                              color={`${theme.palette.mode === "dark" ? "light" : "inherit"}`}
                            />
                          </Stack>
                        ) : (
                          ""
                        )
                      }
                    >
                      Login
                    </CustomButton>
                  </Stack>
                </div>
              </Form>

              <div className="form-note-s2 text-center pt-4">
                New on our platform?{" "}
                <Link to={`${process.env.PUBLIC_URL}/register?type=client`}>Create an account as Client</Link>
              </div>
              <div className="form-note-s2 text-center pt-3">
                New on our platform?{" "}
                <Link to={`${process.env.PUBLIC_URL}/register?type=business`}>Create an account as Business</Link>
              </div>

              <div className="text-center pt-4 pb-3 d-none">
                <h6 className="overline-title overline-title-sap">
                  <span>OR LOGIN WITH</span>
                </h6>
              </div>
              <ul className="nav justify-center gx-4 d-none">
                <li className="nav-item">
                  {/* <button
                    class="btnFG d-flex justify-content-center align-center fw-bold w-150px"
                    onClick={() => googleLogin()}
                  >
                    <Icon
                      name="google"
                      style={{
                        background:
                          "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                        WebkitTextFillColor: "transparent",
                      }}
                      className="fs-4 me-1"
                    />
                    Google
                  </button> */}
                </li>
                <li className="nav-item">
                  {/* <ReactFacebookLogin
                    appId="1118926236567193"
                    autoLoad={true}
                    textButton="Facebook"
                    fields="name,email"
                    callback={responseFacebook}
                    icon={<Icon name="facebook-fill" className="fs-4 h-100 align-center"></Icon>}
                    cssClass="btnFG"
                  /> */}
                </li>
              </ul>
            </PreviewCard>
          </Block>
        </>
      )}
      <AuthFooter />
    </>
  );
};
export default Login;
