import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Spinner } from "reactstrap";
import { CustomButton } from "../../themes/CustomComponent";
import { Stack } from "@mui/material";
import Select, { components } from "react-select";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import { toast } from "react-toastify";
import LogoFetch from "./LogoFetch";
import { getCookie } from "../../utils/Utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);

const Verification = () => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loginWith, setLoginWith] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    country_id: "",
    register_type: "",
  });

  useEffect(() => {
    const cokkieData = getCookie("verify");
    if (cokkieData) {
      setLoginWith(cokkieData);
      Cookies.remove("verify");
    }
  }, [loginWith]);

  console.log("verify :" + loginWith);

  useEffect(() => {
    fetch("https://api.ipartydjcrm.com/api/v1/public/countries")
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries
          .filter((country) => country.id === 101 || country.id === 231) // Filter for ids 101 and 102
          .map((country) => ({
            value: country.shortname,
            label: "+" + country.phonecode,
            id: country.id,
            icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
          }));
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]);
        setFormData({ ...formData, country_id: countriesData?.[0]?.id });

        console.log(countriesData[0]);
        console.log(formData.country_id);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleChange = (value) => {
    setSelectedCountry(value);
    setFormData({ ...formData, country_id: value?.id });
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (form) => {
    const { name, email, phone_number, country_id } = form;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("country", country_id);
      formData.append("event_type", loginWith);
      const logindata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/register-other-platform`, {
        method: "POST",
        body: formData,
      });
      const logininfo = await logindata.json();
      if (logininfo && logininfo.status) {
        const submittedData = logininfo?.data;
        toast.success("Inquiry Sent Successfully");
        // resetForm();
      } else {
        toast.error(logininfo?.errors?.[0]);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-2 text-center">
          <LogoFetch />
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Verify with {loginWith}</BlockTitle>
              <BlockDes className="d-none">
                <p>Create new {process.env.REACT_APP_PROJECT_NAME} account</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Username
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="name"
                  tabIndex={1}
                  {...register("name", {
                    required: "Username is required",
                    pattern: {
                      value: /^[a-zA-Z0-9_]*$/,
                      message: "Username should be alphanumeric without special characters",
                    },
                    minLength: { value: 3, message: "Username must be at least 3 characters long" },
                    maxLength: { value: 255, message: "Username must not exceed 255 characters" },
                  })}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  placeholder="Enter your username"
                  className="form-control-lg bg-transparent border form-control"
                />
                {errors.name && <p className="invalid">{errors.name.message}</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="email"
                  bssize="lg"
                  id="email"
                  tabIndex={2}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Enter a valid email address",
                    },
                    maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                  })}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="form-control-lg bg-transparent border form-control"
                  placeholder="Enter your email address"
                />
                {errors.email && <p className="invalid">{errors.email.message}</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="contactnumber">
                  Contact Number
                </label>
              </div>
              <div className="form-control-wrap d-flex align-center">
                <Select
                  className="w-45"
                  value={selectedCountry}
                  options={countries}
                  onChange={handleChange}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? "white" : "black",
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      display: "flex",
                      alignItems: "center",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
                <input
                  type="number"
                  bssize="lg"
                  id="phone_number"
                  tabIndex={3}
                  {...register("phone_number", {
                    required: "contact number is required",
                    pattern: {
                      value: /^\d{10,15}$/,
                      message: "Enter a valid contact Number (10-15 digits)",
                    },
                  })}
                  maxLength={15}
                  onInput={(e) => {
                    if (e.target.value.length > 15) {
                      e.target.value = e.target.value.slice(0, 15); // Trim value to 15 digits if exceeded
                    }
                  }}
                  onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                  className="form-control-lg bg-transparent border form-control"
                  placeholder="Enter your contact number"
                />
                {errors.phone_number && <p className="invalid">{errors.phone_number.message}</p>}
              </div>
            </div>

            <div className="form-group">
              <Stack>
                {loginWith === "google" ? (
                  <button class="btnFG d-flex justify-content-center align-center fw-bold w-100" type="submit">
                    <Icon
                      name="google"
                      style={{
                        background:
                          "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                        WebkitTextFillColor: "transparent",
                      }}
                      className="fs-4 me-1"
                    />
                    Continue With {loginWith.toUpperCase()}
                  </button>
                ) : loginWith === "facebook" ? (
                  <button class="btnFG d-flex justify-content-center align-center fw-bold w-100" type="submit">
                    <Icon name="facebook-circle" className="fs-4 me-1" color="#1877F2" />
                    Continue With {loginWith.toUpperCase()}
                  </button>
                ) : (
                  ""
                )}
              </Stack>
            </div>
          </form>
        </PreviewCard>
      </Block>
    </>
  );
};

export default Verification;
