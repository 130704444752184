import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Cookies.remove("authToken", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("username", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("email", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("role", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("userId", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("phone", { domain: process.env.REACT_APP_COOKIE_DOMAIN });

    // Cookies.set("user_id", data.data.user_id?.id, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: expires });

    const logoutURLValue = Cookies.get("logoutURL");
    if (logoutURLValue) {
      window.location.href = logoutURLValue;
    } else {
      navigate("/");
    }
  });
  return (
    <>
      <div class="d-flex justify-content-center align-items-center">
        <div class="my-4">... Loading</div>
        
      </div>
    </>
  );
};

export default Logout;
