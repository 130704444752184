import Router from "./route/Index";

import ThemeProvider from "./layout/provider/Theme";
import { ThemeProvider as MuiThemeProvider, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import theme from "./themes";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  const muiTheme = useSelector((state) => state.mui.theme);

  const customization = {
    mode: muiTheme,
    backgroundDark: { main: "#141c26" },
    boxShadow: {
      light: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      dark: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    },
    cardInner: { main: { padding: "1.5rem" }, lg: { padding: "2.5rem" } },
    borderRadius: "0.625rem",
  };
  const clientId = "1020376856068-ifgepldkm2vgjcjgim3h7npbjjipei03.apps.googleusercontent.com";

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <MuiThemeProvider theme={theme(customization)}>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: customization.mode === "dark" ? customization.backgroundDark.main : "white",
              "@media (min-width: 65.5rem)": {
                background: "transparent",
              },
            }}
          >
            <ThemeProvider>
              <Router />
            </ThemeProvider>
          </Paper>
        </MuiThemeProvider>
      </GoogleOAuthProvider>
    </>
  );
};
export default App;
